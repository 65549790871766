<template>
  <b-sidebar
    id="add-new-vendor-sidebar"
    :visible="isAddNewVendorSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-vendor-sidebar-active', val)"
    @shown="showSidebar"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('AppVendor.newVendor') }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Customer-->
          <validation-provider
            #default="{ errors }"
            :name="$t('AppVendor.columns.customer')"
            rules="required"
          >
            <b-form-group
              :label="$t('AppVendor.columns.customer')"
              :state="errors.length > 0 ? false:null"
            >
              <v-select
                v-model="vendorData.clienteId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                label="texto"
                :options="customers"
                :reduce="option => option.id"
              />
              <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Alias-->
          <validation-provider
            #default="validationContext"
            :name="$t('AppVendor.columns.nickname')"
            rules="required|max:10"
          >
            <b-form-group
              :label="$t('AppVendor.columns.nickname')"
              label-for="alias"
            >
              <b-form-input
                id="alias"
                v-model="vendorData.alias"
                autofocus
                :state="getValidationState(validationContext)"
                trim
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Nombre -->
          <validation-provider
            #default="validationContext"
            :name="$t('AppVendor.columns.name')"
            rules="required|max:150"
          >
            <b-form-group
              :label="$t('AppVendor.columns.name')"
              label-for="nombre"
            >
              <b-form-input
                id="nombre"
                v-model="vendorData.nombre"
                :state="getValidationState(validationContext)"
                trim
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- RFC -->
          <validation-provider
            #default="validationContext"
            :name="$t('AppVendor.columns.rfc')"
            rules="required|max:13|min:12"
          >
            <b-form-group
              :label="$t('AppVendor.columns.rfc')"
              label-for="rfc"
            >
              <b-form-input
                id="rfc"
                v-model="vendorData.rfc"
                :state="getValidationState(validationContext)"
                trim
                onkeyup="this.value = this.value.toUpperCase();"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Domicilio -->
          <validation-provider
            #default="validationContext"
            :name="$t('AppVendor.columns.address')"
            rules="required|max:250"
          >
            <b-form-group
              :label="$t('AppVendor.columns.address')"
              label-for="domicilio"
            >
              <b-form-input
                id="domicilio"
                v-model="vendorData.domicilio"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t('Lists.Add') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t('Lists.Cancel') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import {
  required,
  alphaNum,
  email,
  min,
  max,
} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vendorService from '@/services/vendor.service'
import optionsService from '@/services/option.service'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,

    vSelect,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewVendorSidebarActive',
    event: 'update:is-add-new-Vendor-sidebar-active',
  },
  props: {
    isAddNewVendorSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      min,
      max,
    }
  },
  setup(props, { emit }) {
    const {
      createVendor,
    } = vendorService()

    const blankVendorData = {
      clienteId: null,
      alias: '',
      nombre: '',
      rfc: '',
      domicilio: null,
    }

    const vendorData = ref(JSON.parse(JSON.stringify(blankVendorData)))

    const resetvendorData = () => {
      vendorData.value = JSON.parse(JSON.stringify(blankVendorData))
    }

    const onSubmit = () => {
      createVendor(vendorData.value)
        .finally(() => {
          emit('sort-vendor-id-asc')
          emit('refetch-data')
          emit('update:is-add-new-vendor-sidebar-active', false)
        })
    }

    const customers = ref([])

    const { fetchCustomers } = optionsService()
    fetchCustomers(data => {
      customers.value = data

      if (customers.value.length === 1) {
        vendorData.value.clienteId = customers.value[0].id
      }
    })

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetvendorData)

    const showSidebar = () => {
      if (customers.value.length === 1) {
        vendorData.value.clienteId = customers.value[0].id
      }
    }

    return {
      vendorData,
      customers,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
      showSidebar,
    }
  },
}
</script>

<style lang="scss">
//@import '@core/scss/vue/libs/vue-select.scss';

#add-new-vendor-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
