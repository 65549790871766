<template>
  <div>
    <vendor-list-add-new
      :is-add-new-vendor-sidebar-active.sync="isAddNewVendorSidebarActive"
      @refetch-data="() => { refVendorsList.refreshData() }"
    />
    <list
      ref="refVendorsList"
      sort-by="ProveedorId"
      refetch-records-name="proveedores"
      :refetch-records="fetchVendors"
      key-field="ProveedorId"
      report-name="CatalogoProveedores.xlsx"
      :actions="actions"
      :filters.sync="filters"
      :table-columns="tableColumns"
      :table-actions="tableActions"
      :delete-method="deleteVendor"
      :fetch-report="fetchVendorExcel"
    />
  </div>
</template>

<script>
import {
  ref,
} from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import i18n from '@/libs/i18n'
import List from '@/components/List.vue'
import vendorService from '@/services/vendor.service'
import VendorListAddNew from './VendorListAddNew.vue'

export default {
  components: {
    VendorListAddNew,
    List,
  },
  directives: {
    Ripple,
  },
  setup() {
    /* Services */
    const {
      fetchVendors,
      deleteVendor,
      fetchVendorExcel,
    } = vendorService()
    const isAddNewVendorSidebarActive = ref(false)
    const refVendorsList = ref(null)

    /* Configuration */
    const actions = ref([
      {
        label: i18n.t('AppVendor.newVendor'),
        aclAction: 'create',
        aclResource: 'Proveedores',
        click: () => { isAddNewVendorSidebarActive.value = true },
        icon: 'PlusCircleIcon',
      },
      {
        name: 'downloadReport',
        aclAction: 'read',
        aclResource: 'Proveedores',
      },
      {
        name: 'uploadTemplate',
        aclAction: 'create',
        aclResource: 'Proveedores',
      },
    ])

    const filters = ref([
      {
        type: 'select',
        name: 'clienteId',
        label: i18n.t('AppVendor.columns.customer'),
        value: '',
        options: [],
        domainName: 'Clientes',
      },
    ])

    const tableColumns = [
      {
        key: 'proveedorId',
        label: i18n.t('AppVendor.columns.vendorId'),
        sortable: true,
        sortKey: 'ProveedorId',
      },
      {
        key: 'clienteNombre',
        label: i18n.t('AppVendor.columns.customer'),
        sortable: true,
        sortKey: 'Cliente.Nombre',
      },
      {
        key: 'alias',
        label: i18n.t('AppVendor.columns.nickname'),
        sortable: true,
        sortKey: 'Alias',
      },
      {
        key: 'nombre',
        label: i18n.t('AppVendor.columns.name'),
        sortable: true,
        sortKey: 'Nombre',
      },
      {
        key: 'rfc',
        label: i18n.t('AppVendor.columns.rfc'),
        sortable: true,
        sortKey: 'Rfc',
      },
      {
        key: 'domicilio',
        label: i18n.t('AppVendor.columns.address'),
        sortable: true,
        sortkey: 'Domicilio',
      },
      {
        label: i18n.t('Lists.Actions'),
        key: 'actions',
        sortable: false,
      },
    ]
    const tableActions = ref([
      {
        name: 'edit',
        label: i18n.t('Lists.Edit'),
        aclAction: 'write',
        aclResource: 'Proveedores',
        routeName: 'apps-vendors-edit',
        params: ['proveedorId'],
        icon: 'CodepenIcon',
      },
      {
        name: 'delete',
        label: i18n.t('Lists.Delete'),
        aclAction: 'delete',
        aclResource: 'Proveedores',
        params: ['proveedorId'],
        icon: 'TrashIcon',
      },
    ])
    return {
      isAddNewVendorSidebarActive,
      refVendorsList,
      actions,
      filters,
      tableColumns,
      tableActions,
      fetchVendors,
      deleteVendor,
      fetchVendorExcel,
    }
  },
}
</script>
